import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login/Login';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import RootRoutes from './RootRoutes';
import WaitingPage from '@izi-logistics/common/lib/pages/WaitingPage';
import { GlobalNotificationsSocket, GlobalSocketClient } from '@izi-logistics/common/lib/components/Socket';
import { client, URL } from './api/apolloClient';
import Register from './pages/Auth/Register/Register';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import { GlobalChannelSocket } from '@izi-logistics/common/lib/pages/Chat/stores/Channel';
import PublicPage from '../src/pages/Public';
const QuoteDetails = React.lazy(() => import('@izi-logistics/common/lib/pages/Quotes/Details'));
//
export default ({ store }: any) => {
  const accountStore = store.accountStore;
  if (accountStore.isWatingScreen) {
    return (
      <Switch>
        <Route path="*" component={WaitingPage} />
      </Switch>
    );
  }
  const routes = accountStore.isLoggedIn ? (
    <GlobalSocketClient config={{ URL, client }}>
      <GlobalNotificationsSocket>
        <GlobalChannelSocket client={client}>
          <Route path="/public" component={PublicPage} />
          <Switch>
            <Route
              exact
              path="/quote-details/:quoteId"
              component={(props: any) => (
                <PreloadingComponent>
                  <QuoteDetails
                    {...props}
                    config={{
                      client,
                      variables: {
                        fields: [{ name: 'parent', value: props.match.params.quoteId }],
                      },
                    }}
                  />
                </PreloadingComponent>
              )}
            />

            <Route path="/change-password" component={SetPassword} />
            <Route path="/" component={RootRoutes} />
          </Switch>
        </GlobalChannelSocket>
      </GlobalNotificationsSocket>
    </GlobalSocketClient>
  ) : (
    <Switch>
      <Route path="/public" component={PublicPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route path="/change-password" component={SetPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
  return routes;
};
